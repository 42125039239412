import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  DataGrid,
  GridColumnMenu,
} from "@mui/x-data-grid";
import { Button, CircularProgress, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import Papa from 'papaparse';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .app-theme': {
            backgroundColor: '#E3E3E3',
            color: 'black',
          },
          '& .MuiDataGrid-windowContainer': {
            overflow: 'inherit !important',
          },
          '& .MuiDataGrid-window': {
            position: 'sticky',
            top: 0,
          },
          '& .cell-green': {
            backgroundColor: '#97e8af',
          },
          '& .cell-orange': {
            backgroundColor: '#ffc29d',
          },
          '& .cell-red': {
            backgroundColor: '#f27076',
          },
        },
      },
    },
  },
});

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
}
function calculateBilanQualiStatus(salon) {
  const currentDate = new Date();
  const salonEndDate = new Date(salon.EndDate);

  if (!salonEndDate || isNaN(salonEndDate)) {
    return "Date de fin invalide";
  }

  if (currentDate < salonEndDate) {
    return "Sans Objet";
  }

  const bilanQualiFields = [
    "LocationQuality__c",
    "LeadQuality__c",
    "TrafficQuality__c",
    "QualityOfTheReception__c",
    "Observations__c",
    "Improvments__c",
    "ToRedo__c",
  ];

  if (salon.ToRedo__c === "Oui") {
    bilanQualiFields.push("DesiredArea__c", "DesiredLocation__c");
  }
  
  const emptyFields = bilanQualiFields.filter(
    (field) => salon[field] === null || salon[field] === undefined || salon[field] === ""
  );

  if (emptyFields.length === 0) {
    return "Complet";
  }

  if (
    currentDate >= salonEndDate &&
    emptyFields.length === bilanQualiFields.length
  ) {
    return "Non saisi";
  }

  if (currentDate >= salonEndDate && emptyFields.length > 0) {
    return "Partiel";
  }
}


function SalonList() {
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;

  const [loading, setLoading] = useState(true);


  const [salons, setSalons] = useState([]);
  const [error, setError] = useState(null);
  const [new_sk, setNew_sk] = useState(null);
  const [userSchools, setUserSchools] = useState([])
  const [loadingSchools, setLoadingSchools] = useState([])

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/events/key`, { withCredentials: true })
      .then((response) => {
        setNew_sk(response.data.key);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error.message);
      });
  }, [SERVER_URL]);

 
useEffect(() => {
    if (new_sk) {
      const userCookie = Cookies.get("user");
      if (userCookie) {
        try {
          const bytes = CryptoJS.AES.decrypt(userCookie, new_sk);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const userData = JSON.parse(decryptedData);
          if (!userData?.schools )
          {
            console.error("Une erreur est survenue. Veuillez vous déconnecter puis reconnecter")
            return;
          }
          const schoolsArray = userData.schools ? userData.schools.split(';').map(school => school.trim()) : [];
          setUserSchools(schoolsArray);
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoadingSchools(false); 
        }
      } else {
        setLoadingSchools(false);
      }
    } else {
      setLoadingSchools(true);
    }
  }, [new_sk]);

  useEffect(() => {
    if (new_sk) {
      const token = localStorage.getItem('jwtToken');
      axios
        .get(`${SERVER_URL}/events/getSalonsList`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.success) {
            const salonsBilanQuali = response.data.data.map((salon) => {
              const name = salon.Name ? salon.Name.toUpperCase() : '';
              const organizer = salon.Organizer__r?.Name ?? '';
              return {
                ...salon,
                Name: name,
                Organizer: organizer,
                bilanQualiStatus: calculateBilanQualiStatus(salon),
              };
            });
            setSalons(salonsBilanQuali);
          } else {
            console.error("Failed to fetch salons list:", response.data.error);
            setError(response.data.error);
          }
        })
        .catch((err) => {
          console.error("An error occurred:", err);
          setError(err.message);
        });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_sk]);

  const hasMultipleSchools = userSchools.length > 1;

  const columns = [
    ...(hasMultipleSchools ? [{
      field: "School__c",
      headerName: "Ecole",
      headerClassName: "app-theme",
      width: 90,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    }] : []),
    {
      field: "Name",
      headerName: "Nom du salon (lien vers la fiche)",
      headerClassName: "app-theme",
      width: 680,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <Link to={`/FicheSalon/${params.row.Id}`}
            style={{
              color: '#3f51b5',
            }}>{params.row.Name}</Link>
        </Tooltip>
      ),
    },
    {
      field: "Detail__c",
      headerName: "Type de salon",
      headerClassName: "app-theme",
      width: 110,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "StartDate",
      headerName: "Date de début",
      headerClassName: "app-theme",
      width: 110,
      valueFormatter: (params) => {
        const value = params;
        if (!value) return '';
        const [year, month, day] = value.split('-');
        const date = new Date(year, month - 1, day);
        return !isNaN(date) ? date.toLocaleDateString() : 'Invalid Date';
      },
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.formattedValue || 'Invalid Date'}</span>
        </Tooltip>
      ),
    },
    {
      field: "EndDate",
      headerName: "Date de fin",
      headerClassName: "app-theme",
      width: 110,
      valueFormatter: (params) => {
        const value = params;
        if (!value) return '';
        const [year, month, day] = value.split('-');
        const date = new Date(year, month - 1, day);
        return !isNaN(date) ? date.toLocaleDateString() : 'Invalid Date';
      },
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.formattedValue || 'Invalid Date'}</span>
        </Tooltip>
      ),
    },

    {
      field: "Organizer",
      headerName: "Organisateur",
      headerClassName: "app-theme",
      width: 110,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "City__c",
      headerName: "Ville du Salon",
      headerClassName: "app-theme",
      width: 110,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "Campus__c",
      headerName: "Campus",
      headerClassName: "app-theme",
      width: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "bilanQualiStatus",
      headerName: "Statut bilan qualitatif",
      headerClassName: "app-theme",
      cellClassName: (params) => {
        if (params.value === "Complet") {
          return 'cell-green';
        }
        if (params.value === "Partiel") {
          return 'cell-orange';
        }
        if (params.value === "Non saisi") {
          return 'cell-red';
        }
      },
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "ProspectsMetN__c",
      headerName: "Prospects N",
      headerClassName: "app-theme",
      align: 'right',
      width: 110,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "ProspectsMetN1__c",
      headerName: "Prospects N-1",
      headerClassName: "app-theme",
      align: 'right',
      width: 110,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "ProspectsMetN2__c",
      headerName: "Prospects N- 2",
      headerClassName: "app-theme",
      align: 'right',
      width: 112,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "PotentielCandidateN__c",
      headerName: "Potentiels N",
      headerClassName: "app-theme",
      align: 'right',
      width: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "PotentialCandidateN1__c",
      headerName: "Potentiels N-1",
      headerClassName: "app-theme",
      align: 'right',
      width: 115,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "PotentialCandidateN2__c",
      headerName: "Potentiels N-2",
      headerClassName: "app-theme",
      align: 'right',
      width: 115,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "DCN__c",
      headerName: "Candidats N",
      headerClassName: "app-theme",
      align: 'right',
      width: 105,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "DCN1__c",
      headerName: "Candidats N-1",
      headerClassName: "app-theme",
      align: 'right',
      width: 115,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "DCN2__c",
      headerName: "Candidats N-2",
      headerClassName: "app-theme",
      align: 'right',
      width: 115,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "RegisteredN__c",
      headerName: "Inscrits N",
      headerClassName: "app-theme",
      align: 'right',
      width: 85,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "RegisteredN1__c",
      headerName: "Inscrits N-1",
      headerClassName: "app-theme",
      align: 'right',
      width: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "RegisteredN2__c",
      headerName: "Inscrits N-2",
      headerClassName: "app-theme",
      align: 'right',
      width: 100,
      renderCell: (params) => (
        <Tooltip title={params.row.Name}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ];

  if (error) {
    return <div>Error: {error}</div>;
  }
  function DownloadButton({ data }) {
    const propertyMapping = {
      'Organizer': 'Organisateur',
      'School__c': 'Ecole',
      'ToRedo__c': 'A refaire',
      'LocationQuality__c': 'Qualité de l\'emplacement',
      'LeadQuality__c': 'Qualité des prospects',
      'TrafficQuality__c': 'Qualité du trafic',
      'QualityOfTheReception__c': 'Qualité de l\'organisation',
      'Observations__c': 'Observations',
      'Improvments__c': 'Axes d\'amélioration',
      'DesiredArea__c': 'Surface souhaitée l\'an prochain',
      'DesiredLocation__c': 'Emplacement souhaité l\'an prochain',
      'Campus__c': 'Campus',
      'Detail__c': 'Type de salon',
      'EndDate': 'Date de fin',
      'StartDate': 'Date de début',
      'Name': 'Nom du salon',
      'DCN__c': 'Candidats n',
      'DCN1__c': 'Candidats n-1',
      'DCN2__c': 'Candidats n-2',
      'PotentielCandidateN__c': 'Candidats potentiels N',
      'PotentialCandidateN1__c': 'Candidats potentiels N-1',
      'PotentialCandidateN2__c': 'Candidats potentiels N-2',
      'ProspectsMetN__c': 'Prospects rencontrés N',
      'ProspectsMetN1__c': 'Prospects rencontrés N-1',
      'ProspectsMetN2__c': 'Prospects rencontrés N-2',
      'RegisteredN__c': 'Inscrits N',
      'RegisteredN1__c': 'Inscrits N-1',
      'RegisteredN2__c': 'Inscrits N-2',
      'City__c': 'Ville du salon',
    };

    const mappedSalons = data.map((salon) => {
      return Object.keys(propertyMapping).reduce((acc, key) => {
        if (salon[key]) {
          let newKey = propertyMapping[key];
          acc[newKey] = salon[key];
        }
        return acc;
      }, {});
    });
    const downloadCSV = () => {
      const csv = Papa.unparse(mappedSalons);
      const csvData = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'bilan des salons de l\'annee.csv';
      link.click();
    };

    if (loading || loadingSchools) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Button onClick={downloadCSV}
        sx={{
          backgroundColor: "white",
          color: "#3f51b5",
          border: "1px solid #3f51b5",
          '&:hover': {
            backgroundColor: "#3f51b5",
            color: "white"
          }
        }
        }
      >
        Télecharger au format csv
      </Button>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100%", width: "98%", margin: '100px auto' }}>
        <div style={{ marginBottom: "20px" }}>
          <DownloadButton data={salons} />
        </div>
        <DataGrid
          style={{ height: 900, width: "100%" }}
          rows={salons}
          columns={columns}
          showCellVerticalBorder
          showColumnVerticalBorder
          getRowId={(row) => row.Id}
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          autosizeOptions={{
            columns: columns.map(col => col.field),
            includeOutliers: true,
            includeHeaders: true,
          }}
           disableColumnResize={false}
        />
      </div>
    </ThemeProvider>
  );
}

export default SalonList;



