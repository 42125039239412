import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  createTheme,
  ThemeProvider,
  Paper,
  Box,
  Grid,
  CircularProgress
} from "@mui/material";
import ecoles from "../components/json/ecoles.json";
import campuses from "../components/json/campus.json";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
});

function UserUpdate() {
  const { id } = useParams();
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const methods = useForm();
  const { errors, control, watch, setValue, handleSubmit } = methods;

  const [user, setUser] = useState(null);
  const [updateStatus, setUpdateStatus] = useState("");
  const visibility = watch("Visibility__c");
  const navigate = useNavigate();

  // Fetching user data
  useEffect(() => {
    axios
      .get(`${SERVER_URL}/events/getSingleUser`, { params: { userId: id } })
      .then((response) => {
        const userData = response.data.data[0];
        setUser(userData);
        
        setValue("FirstName__c", userData.FirstName__c);
        setValue("LastName__c", userData.LastName__c);
        setValue("Email__c", userData.Email__c);
        setValue("Schools__c", userData.Schools__c.split("; ")); 
        setValue("Visibility__c", userData.Visibility__c);
        setValue("Campus__c", userData.Campus__c);
      })
      .catch((error) => {
        console.error("Une erreur est survenue:", error);
      });
  }, [id, SERVER_URL, setValue]);

  useEffect(() => {
    const schools = watch("Schools__c") || [];
    if (schools.length > 1) {
      setValue('Visibility__c', 'Nationale');
    } else {
      setValue('Visibility__c', 'Campus');
    }
  }, [watch, setValue]);

  const handleUpdate = async (data) => {
    try {
      const userUpdate = {
        ...user,
        Schools__c: Array.isArray(data.Schools__c) ? data.Schools__c.join("; ") : data.Schools__c,
        UserDivision__c: data.Schools__c.join("; "),
        FirstName__c: data.FirstName__c,
        LastName__c: data.LastName__c,
        Email__c: data.Email__c,
        Visibility__c: data.Visibility__c,
        Campus__c: data.Visibility__c === 'Nationale' ? "" : data.Campus__c
      };

      const response = await axios.patch(`${SERVER_URL}/events/updateUser/`, userUpdate);

      if (response.data.success) {
        setUpdateStatus("Utilisateur mis à jour avec succès !");
        setTimeout(() => {
          navigate("/UserList");
        }, 3000);
      } else {
        console.error("Une erreur est survenue lors de la mise à jour de l'utilisateur.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper
        variant="outlined"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
        }}
      >
        <h1 style={{ fontFamily: "Arial, sans-serif", fontWeight: "light" }}>
          Mise à jour de l'utilisateur
        </h1>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <div>
            <TextField
              label="Prénom"
              {...control.register("FirstName__c")}
              variant="outlined"
              margin="normal"
              fullWidth
              focused
            />
            <TextField
              label="Nom"
              {...control.register("LastName__c")}
              variant="outlined"
              margin="normal"
              fullWidth
              focused
            />
            <TextField
              label="Email"
              {...control.register("Email__c")}
              variant="outlined"
              margin="normal"
              fullWidth
              focused
            />
            <FormControl
              variant="outlined"
              fullWidth
              required
              style={{ marginBottom: "20px" }}
            >
              <InputLabel id="school-label">Ecoles</InputLabel>
              <Controller
                name="Schools__c"
                defaultValue={[]}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    label="Ecoles"
                    error={Boolean(errors?.Schools__c)}
                    onChange={(event) => {
                      const selectedValues = event.target.value;

                      if (selectedValues.includes("clearAll")) {
                        setValue("Schools__c", []); 
                      } else {
                        setValue("Schools__c", selectedValues); 
                      }
                    }}
                    renderValue={(selected) => (
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', gap: '5px',
                        mawWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis'
                      }}>
                        {selected.length > 0 ? (
                          selected.map((value) => (
                            <span key={value} style={{ whiteSpace: 'nowrap' }}>
                              {value}
                            </span>
                          ))
                        ) : (
                          <span style={{ color: '#999' }}>Aucune école sélectionnée</span>
                        )}
                      </div>
                    )}
                  >
                    <MenuItem value="clearAll">
                      <em>Effacer toutes les écoles</em>
                    </MenuItem>

                    {ecoles.map((ecole, i) => (
                      <MenuItem key={i} value={ecole}>
                        {ecole}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />



            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <InputLabel id="visibility-label">Visibilité</InputLabel>
              <Controller
                name="Visibility__c"
                control={control}
                render={({ field }) => (
                  <Select {...field} label="Visibilité">
                    <MenuItem value="Nationale">Nationale</MenuItem>
                    <MenuItem value="Campus">Campus</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {visibility !== "Nationale" && (
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "20px" }}
              >
                <InputLabel id="campus-label">Campus</InputLabel>
                <Controller
                  name="Campus__c"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Campus">
                      {campuses.map((campus, i) => (
                        <MenuItem key={i} value={campus}>
                          {campus}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={5}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Mettre à jour
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component={Link}
                  to="/UserList"
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>
            <p style={{ fontFamily: "Arial, sans-serif", fontWeight: "light" , color:'green'}}>
              {updateStatus}
            </p>
          </div>
        </form>
      </Paper>
    </ThemeProvider>
  );
}

export default UserUpdate;
