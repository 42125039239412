import {
  Routes,
  Route,
  Link as RouterLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useState, useEffect, useContext, createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CreateUser from "./components/userCreation";
import LoginForm from "./components/login";
import ForgotPassword from "./components/passwordReinitialization";
import PasswordUpdate from "./components/passwordUpdate";
import SalonList from "./components/salonsList";
import FicheSalon from "./components/ficheSalon";
import UserList from "./components/userList";
import UpdateFicheSalon from "./components/updateFicheSalons";
import { SalonDataProvider } from './components/salonDataContext';
import UpdateUser from "./components/userUpdate";
import UserContext from "./components/userContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ionis_logo from "./images/ionis_logo.png";

export const DeadlineContext = createContext();


function LogoutButton() {
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const { setIsLoggedIn, setRole } = useContext(UserContext);

  const handleLogout = () => {
    const token = localStorage.getItem('jwtToken');

    axios
      .get(`${SERVER_URL}/events/logout`, {}, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
      })
      .then((response) => {
        if (response.data.message === "Déconnexion réussie.") {
          setIsLoggedIn(false);
          Cookies.remove("user");
          localStorage.removeItem('jwtToken');
          navigate("/login");
          setRole(null);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => console.error("Error:", error));
  };
  return (
    <Button
      color="inherit"
      onClick={handleLogout}
      sx={{
        backgroundColor: "#b3070f",
        fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
        padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 26px' }
      }}
    >
      Déconnexion
    </Button>
  );
}

function App() {
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;


  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);
  const location = useLocation();
  const [deadline, setDeadline] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [new_sk, setNew_sk] = useState(null);

  const isUpdatingSalon = location.pathname ? location.pathname.includes("/UpdateFicheSalon") : false;

  const deleteCookie = (name) => {
    document.cookie = name + '=; Max-Age=0; path=/;';
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const [navEntry] = performance.getEntriesByType("navigation") || [{}];
      if (navEntry.type !== 'reload') {
        deleteCookie('user');
        setIsLoggedIn(false);
        localStorage.removeItem('jwtToken');
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  useEffect(() => {
    const user = Cookies.get("user");
    if (user) {
      setUser(user);
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    axios.get(`${SERVER_URL}/events/key`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true })
      .then((response) => {
        setNew_sk(response.data.key);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      }
      )
  }, [SERVER_URL]);

  useEffect(() => {
    const fetchDeadline = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/events/getDeadline`, {
          withCredentials: true,
        });
        if (response.data) {
          setDeadline(response.data.data[0]?.Deadline__c);
        }
      } catch (error) {
        console.error("Une erreur est survenue:", error);
      }
    }
    fetchDeadline();
  },);


  useEffect(() => {
    const checkLogin = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${SERVER_URL}/events/isLoggedIn`, {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        setIsLoggedIn(response.data.isLoggedIn);

        if (response.data.isLoggedIn) {
          const userResponse = await axios.get(`${SERVER_URL}/events/getLogin`, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
          const user = userResponse.data.data[0];
          setUser(user);
        }
        setLoading(false);
      } catch (error) {
        console.error("Une erreur est survenue:", error);
        setLoading(false);
        setIsLoggedIn(false);
      }
    };
    checkLogin();
  }, [SERVER_URL]);


  const encryptedCookie = Cookies.get("user");
  useEffect(() => {
    if (encryptedCookie && new_sk) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedCookie, new_sk);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const user = JSON.parse(decryptedData);
        setUser(user);
        setRole(user.role);
        setFirstName(user.firstName);
      } catch (error) {
        console.error("Error decrypting or parsing JSON:", error);
      }
    }
  }, [new_sk, encryptedCookie]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#dc004e",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          asterisk: {
            color: "#d32f2f"
          }
        }
      }
    }
  });


  return (
    <UserContext.Provider value={{ user, setUser, setIsLoggedIn, isLoggedIn, role, setRole }}>
      <DeadlineContext.Provider value={deadline}>
        <SalonDataProvider>
          <ThemeProvider theme={theme}>
            {isLoggedIn && (
              <AppBar position="absolute" style={{ width: "100%" }}>
                <Toolbar>
                  <img
                    src={ionis_logo}
                    alt="Logo"
                    style={{ height: "40px", marginRight: 15 }}
                  />
                  <Typography variant="h6" sx={{
                    flexGrow: 1, fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
                    padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' }
                  }}>
                    Vos salons de l'année et leurs bilans{" "}
                  </Typography>
                  <Typography variant="h6" sx={{
                    flexGrow: 1, fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
                    padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' }
                  }}>
                    Bienvenue {firstName} !
                  </Typography>
                  {user && role === "Admin" && (
                    <>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/CreateUser"
                        sx={{
                          fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
                          padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' }
                        }}
                      >
                        Créer un utilisateur
                      </Button>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/UserList"
                        sx={{
                          fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
                          padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' }
                        }}
                      >
                        Liste des utilisateurs
                      </Button>
                    </>
                  )}
                  {!isUpdatingSalon && (
                    <>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/SalonsList"
                        sx={{
                          fontSize: { xs: '0.35rem', sm: '0.45rem', md: '1rem' },
                          padding: { xs: '6px 12px', sm: '8px 16px', md: '10px 20px' }
                        }}
                      >
                        Vos salons
                      </Button>
                      <LogoutButton />
                    </>
                  )}
                </Toolbar>
              </AppBar>
            )}
            <Box mt={4}>
              <Routes>
                <Route path="/" element={<LoginForm setRole={setRole} />} />
                <Route path="/login" element={isLoggedIn ? <SalonList /> : <LoginForm />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/PasswordUpdate/:token" element={<PasswordUpdate />} />
                <Route path="/SalonsList" element={!isLoggedIn ? <LoginForm /> : <SalonList />} />
                <Route path="/FicheSalon/:id" element={!isLoggedIn ? <LoginForm /> : <FicheSalon />} />
                <Route
                  path="/UpdateFicheSalon/:id"
                  element={!isLoggedIn ? <LoginForm /> : <UpdateFicheSalon />}
                />
                <Route path="/UpdateUser/:id" element={isLoggedIn ? (role === "Admin" ? <UpdateUser setRole={setRole} /> : <SalonList />) : <LoginForm />} />
                <Route path="/CreateUser" element={isLoggedIn ? (role === "Admin" ? <CreateUser setRole={setRole} /> : <SalonList />) : <LoginForm />} />
                <Route path="/UserList" element={isLoggedIn ? (role === "Admin" ? <UserList setRole={setRole} /> : <SalonList />) : <LoginForm />} />
              </Routes>
            </Box>
          </ThemeProvider>
        </SalonDataProvider>
      </DeadlineContext.Provider>
    </UserContext.Provider>
  );
}

export default App;










