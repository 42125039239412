import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .app-theme": {
            backgroundColor: "#E3E3E3",
            color: "black",
          },
        },
      },
    },
  },
});

function UserList() {
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/events/getUsersList`)
      .then((response) => {
        let data = response.data.data;
        if (!Array.isArray(data)) {
          data = [data];
        }
        const users = data.map((user, index) => ({
          id: user.Id,
          ...user,
          FullName: `${user.FirstName__c} ${user.LastName__c}`,
        }));
        setUsers(users);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [SERVER_URL]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${SERVER_URL}/events/deleteSalonUser`, {
        params: { Id: id },
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("Utilisateur supprimé avec succès.");
        setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
      } else {
        console.error("Une erreur est survenue lors de la suppresion de l'utilisateur:", response.data);
      }
    } catch (error) {
      console.error("Une erreur est survenue lors de la suppresion de l'utilisateur:", error, error.response.data);
    }
  };

  const columns = [
    {
      field: "FirstName__c",
      headerName: "Prénom",
      headerClassName: "app-theme",
      width: 130,
    },
    {
      field: "LastName__c",
      headerName: "Nom",
      headerClassName: "app-theme",
      width: 130,
    },
    {
      field: "Email__c",
      headerName: "Adresse mail",
      headerClassName: "app-theme",
      width: 300,
    },
    {
      field: "Schools__c",
      headerName: "Ecoles",
      headerClassName: "app-theme",
      width: 400,
    },
    {
      field: "Visibility__c",
      headerName: "Visibilité",
      headerClassName: "app-theme",
      width: 150,
    },
    {
      field: "Campus__c",
      headerName: "Campus",
      headerClassName: "app-theme",
      width: 150,
    },
    {
      field: "LastConnection__c",
      headerName: "Date de la dernière connexion",
      headerClassName: "app-theme",
      valueFormatter: (params) => new Date(params).toLocaleString(),
      width: 220,
    },
    {
      field: "UserCreationDate__c",
      headerName: "Date de réation",
      headerClassName: "app-theme",
      valueFormatter: (params) => new Date(params).toLocaleDateString(),
      width: 200,
    },
    {
      field: "update",
      headerName: "",
      headerClassName: "app-theme",
      width: 100,
      renderCell: (params) => (
        <Link
          to={`/UpdateUser/${params.row.id}`}
          style={{
            color: "#3f51b5",
          }}
        >
          Modifier
        </Link>
      ),
    },
    {
      field: "delete",
      headerName: "",
      headerClassName: "app-theme",
      width: 100,
      renderCell: (params) => (
        <Link
          component="button"
          style={{
            color: "#3f51b5",
          }}
          variant="body2"
          onClick={(event) => {
            event.preventDefault();
            handleDelete(params.row.Id);
          }}
        >
          Supprimer
        </Link>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: 400, width: "93.6%", margin: '90px auto' }}>
        <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }} >Liste des utilisateurs</h1>
        <DataGrid rows={users} columns={columns} pageSize={5} />
      </div>
    </ThemeProvider>
  );
}

export default UserList;



