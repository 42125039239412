import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
    Email__c: Yup.string().email('Veuillez entrer une adresse mail valide.').required('Ce champ est requis.'),
    Password__c: Yup.string().required('Ce champ est requis.'),
});

export default loginSchema;


const salonUpdateSchema = {
    'quantitatif': Yup.object().shape({
        ProspectsMetN__c: Yup.number()
            .nonNullable("Le champ ne peut pas être nul")
            .min(0, 'Le nombre de prospects ne peut pas être négatif.'),
        PotentielCandidateN__c: Yup.number()
            .nonNullable("Le champ ne peut pas être nul")
            .min(0, 'Le nombre de candidats potentiels ne peut pas être négatif.')
            .max(Yup.ref('ProspectsMetN__c'), 'Le nombre de candidats potentiels ne peut pas dépasser le nombre de prospects.'),
        DCN__c: Yup.number()
            .nonNullable("Le champ ne peut pas être nul")
            .min(0, 'Le nombre de candidats ne peut pas être négatif.')
            .max(Yup.ref('PotentielCandidateN__c'), 'Le nombre de candidats ne peut pas dépasser le nombre de candidats potentiels ou de prospects.'),
        RegisteredN__c: Yup.number()
            .nonNullable("Le champ ne peut pas être nul")
            .min(0, 'Le nombre d\'inscrits ne peut pas être négatif.')
            .max(Yup.ref('DCN__c'), 'Le nombre d\'inscrits ne peut pas dépasser le nombre de candidats, de candidats potentiels ou de prospects.'),
    }),
    'qualitatif': Yup.object().shape({
        LocationQuality__c: Yup.number()
            .min(0, 'La qualité de l\'emplacement doit être entre 0 et 10.')
            .max(10, 'La qualité de l\'emplacement doit être entre 0 et 10.'),
        LeadQuality__c: Yup.number()
            .min(0, 'La qualité des prospects doit être entre 0 et 10.')
            .max(10, 'La qualité des prospects doit être entre 0 et 10.'),
        TrafficQuality__c: Yup.number()
            .min(0, 'La qualité du trafic doit être entre 0 et 10.')
            .max(10, 'La qualité du trafic doit être entre 0 et 10.'),
        QualityOfTheReception__c: Yup.number()
            .min(0, 'La qualité de l\'organisation doit être entre 0 et 10.')
            .max(10, 'La qualité de l\'organisation doit être entre 0 et 10.'),
        DesiredArea__c: Yup.string()
            .nullable()
    }),
}

export { salonUpdateSchema };

const userCreateSchema = Yup.object().shape({
    FirstName__c: Yup.string().required('Ce champ est requis.'),
    LastName__c: Yup.string().required('Ce champ est requis.'),
    Email__c: Yup.string().email('Veuillez entrer une adresse mail valide.').required('Ce champ est requis.'),
    Password__c: Yup.string().required('Ce champ est requis.'),
    Schools__c: Yup.array()
        .of(Yup.string().required('Une école valide est requise.'))
        .min(1, 'Vous devez sélectionner au moins une école.')
        .required('Ce champ est requis.'),
    Visibility__c: Yup.string().required('Ce champ est requis.'),
});

export { userCreateSchema };


